import React, { useCallback, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { CurrentUserContext } from '../../../providers/CurrentUserContext'
import Routes from '../../../startup/routes'
import LogoutLink from '../users/LogoutLink'

type UserSidebarProps = {
  onLinkClick?(...args: unknown[]): unknown
}

const UserSidebar: React.FC<UserSidebarProps> = ({ onLinkClick }) => {
  const { currentUser } = useContext(CurrentUserContext)

  const renderYourStories = useCallback(() => {
    const showStories = currentUser && (currentUser.isInRole('admin') || currentUser.hasRole('curator') || currentUser.hasRole('author'))

    if (showStories) {
      return (
        <li>
          <NavLink to={Routes.yourStories()} className="link" onClick={onLinkClick}>
            <i className="icon-bullhorn-blue" />
            <span className="link-text"> Your Stories</span>
          </NavLink>
        </li>
      )
    }
  }, [currentUser, onLinkClick])

  const renderTopMenu = useCallback(() => {
    return (
      <ul>
        <li>
          <NavLink to={Routes.dashWelcome()} className="link" onClick={onLinkClick}>
            <i className="icon-kite-green" />
            <span className="link-text"> Welcome</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={Routes.profile()} className="link" onClick={onLinkClick}>
            <i className="icon-user-silver" />
            <span className="link-text"> Account</span>
          </NavLink>
        </li>
      </ul>
    )
  }, [onLinkClick])

  return (
    <div className="dash-nav-block">
      <div className="dash-nav-subgroup">{renderTopMenu()}</div>

      <div className="dash-nav-subgroup">
        <h4 className="dash-nav-heading">Submit Content</h4>
        <ul>
          <li>
            <NavLink to={Routes.yourEvents()} className="link" onClick={onLinkClick}>
              <i className="icon-events-red" />
              <span className="link-text"> Your Events</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={Routes.yourPlaces()} className="link" onClick={onLinkClick}>
              <i className="icon-pin-green" />
              <span className="link-text"> Your Places</span>
            </NavLink>
          </li>
          {renderYourStories()}
        </ul>
      </div>

      <div className="dash-nav-subgroup">
        <ul>
          <li>
            <LogoutLink>
              <i className="icon-logout-white" />
              <span className="link-text"> Log out</span>
            </LogoutLink>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default UserSidebar
