import gql from 'graphql-tag'
import pick from 'lodash/pick'
import React, { createContext, useMemo } from 'react'
import { useQuery } from '../api/graphql'
import { User } from '../models'

type ContextType = { currentUser: null | User; loading: boolean }
export const CurrentUserContext = createContext<ContextType>({ currentUser: null, loading: true })

export const currentUserFragment = gql`
  fragment currentUserFragment on User {
    id
    firstName
    lastName
    email
    profilePic_publicId: profilePicPublicId
    primaryCommunity {
      id
      liveState
      slug
    }
    roles {
      name
      resourceId
    }
  }
`

export const CurrentUserQuery = gql`
  query CurrentUser {
    currentUser {
      ...currentUserFragment
    }
  }
  ${currentUserFragment}
`

const CurrentUserProvider: React.FC = ({ children }) => {
  const { data: { currentUser } = {}, loading }: { data: { currentUser?: User }; loading: boolean } = useQuery(CurrentUserQuery, {})

  const value = useMemo(() => ({ currentUser, loading, isLoggedIn: !!currentUser }), [currentUser, loading])

  if (currentUser) {
    window.Rollbar &&
      window.Rollbar.configure({
        payload: { person: pick(currentUser, ['id', 'firstName', 'lastName', 'email', 'primaryCommunity', 'roles']) },
      })
  }

  return <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>
}

export default CurrentUserProvider
