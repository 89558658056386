import { css } from '@emotion/react'
import dayjs from 'dayjs'
import * as React from 'react'
import BodyClassName from 'react-body-classname'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import toastr from 'toastr'
import { getPlaceAddress } from '../../../api/places/helpers'
import { Event } from '../../../models'
import Routes, { closestEventsPath } from '../../../startup/routes'
import { formatDateAndTime, formatTime, getDuration, getTimeFromNow, isSameDay } from '../../../util/dateTime'
import { toMoney } from '../../../util/moneyFormat'
import CalendarContainer from '../../containers/misc/CalendarContainer'
import { createMarkup } from '../../helpers/helpers'
import Analytics from '../misc/Analytics'
import EmbeddedModalLinkBlocker from '../misc/EmbeddedModalLinkBlocker'
import Loading from '../misc/Loading'
import ResponsiveImage, { mediaSrc } from '../misc/MediaPresets'
import { ActionControls } from '../misc/RecordView'
import EventSignUpUpsell from './EventSignUpUpsell'

const Metadata: React.FC<{ event: Event }> = ({ event }) => (
  <Helmet>
    <title>{event.title}</title>
    {event.image && <meta property="og:image" content={mediaSrc(event.image)} />}
    {event && <link rel="canonical" href={Routes.customUrl(event.community, Routes.event(event, event.community))} />}
  </Helmet>
)

const EventStart: React.FC<{ event: Event }> = ({ event }) => {
  if (event.startAt) {
    return (
      <div className="event-date">
        <h2 className="single-event-subtitle">
          <i className="icon-watch-red" />
          Starts
        </h2>
        <strong>{formatDateAndTime(event.startAt)}</strong>
        <div className="event-relative-time">({getTimeFromNow(event.startAt)})</div>
      </div>
    )
  }
  return null
}

const EventEnd: React.FC<{ event: Event }> = ({ event }) => {
  if (event.endAt) {
    return (
      <div className="event-date">
        <h2 className="single-event-subtitle">
          <i className="icon-watch-red" />
          Ends
        </h2>
        <strong>{isSameDay(event.startAt, event.endAt) ? formatTime(event.endAt) : formatDateAndTime(event.endAt)}</strong>
        <div className="event-relative-time">{event.startAt ? `(${getDuration(event.startAt, event.endAt)} long)` : ''}</div>
      </div>
    )
  }
  return null
}

const EventPlaceName: React.FC<{ event: Event }> = ({ event }) => {
  if (event.place) return <Link to={Routes.place(event.place, event.community)}>{event.place.title}</Link>
  if (event.tempPlace) return <div>{event.tempPlace.name}</div>
  return null
}

const placeStreet = (event): string => {
  const place = event.place || event.tempPlace
  return place?.address?.streetAddress
}

const EventPlace: React.FC<{ event: Event }> = ({ event }) => (
  <>
    <h2 className="single-event-subtitle">
      <i className="icon-pin-green" />
      Place
    </h2>
    <strong>
      <EventPlaceName event={event} />
    </strong>

    <div className="event-place-street">{placeStreet(event)}</div>
  </>
)

const virtualPlaceCta = (event: Event): string => {
  const isStartInTheFuture = dayjs().isBefore(dayjs(event.startAt))
  if (isStartInTheFuture) return 'Event Link'

  if (event.endAt) {
    const isEventHappeningNow = dayjs().isBefore(dayjs(event.endAt))
    if (isEventHappeningNow) return 'Join Now'
  }
  return 'Event Link'
}

const truncateUrl = (url: string): string => url.replace(/^.*\/\/(www\.)?/, '')

const VirtualPlace: React.FC<{ event: Event }> = ({ event }) => (
  <a href={event.url} target="_blank" rel="noopener noreferrer">
    <h2 className="single-event-subtitle">
      <i className="icon-wifi-green" />
      Online Event
    </h2>
    <strong>{virtualPlaceCta(event)}</strong>
    <div className="event-url">{truncateUrl(event.url || '')}</div>
  </a>
)

const EventCost: React.FC<{ costMin?: number; costMax?: number }> = ({ costMin, costMax }) => (
  <div className="event-cost">
    <h2 className="single-event-subtitle">
      <i className="icon-ticket-yellow" />
      Cost
    </h2>

    <strong>
      {costMin === 0 && costMax === 0 ? 'FREE' : costMin === costMax ? toMoney(costMin) : `${toMoney(costMin)} - ${toMoney(costMax)}`}
    </strong>
  </div>
)

const EventHeader: React.FC<{ event: Event }> = ({ event }) => {
  const image = event.image ? event.image.publicId : 'https://unsplash.it/1880/440'
  return (
    <header className="single-event-header">
      <hgroup className="single-event-top">
        <h1 className="single-event-title">{event.title}</h1>
      </hgroup>

      <div className="single-event-image">
        <Loading
          className="isCentered isDark"
          css={css`
            position: absolute;
            top: calc(50% - 13px);
            left: calc(50% - 13px);
            margin: 0 !important;
          `}
        />
        <ResponsiveImage publicId={image} />
      </div>

      <div className="event-meta">
        <div className="clearfix">
          <EventStart event={event} />
          <EventEnd event={event} />
        </div>

        <div className="clearfix">
          <div className="event-place">{event.isVirtual ? <VirtualPlace event={event} /> : <EventPlace event={event} />}</div>

          <EventCost costMin={event.costMin} costMax={event.costMax} />
        </div>
      </div>
    </header>
  )
}

const EventVenue: React.FC<{ event: Event }> = ({ event }) => {
  if (event.isVirtual) {
    return (
      <a href={event.url} target="_blank" rel="noopener noreferrer">
        <div>
          <h2 className="single-event-subtitle">
            <i className="icon-wifi" /> Online Event
          </h2>
          <strong>{virtualPlaceCta(event)}</strong>
          <div
            className="event-url"
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {truncateUrl(event.url || '')}
          </div>
        </div>
      </a>
    )
  }
  const place = event.place || event.tempPlace
  return (
    <div>
      <h2 className="single-event-subtitle">
        <i className="icon-pin" /> Place
      </h2>
      <div>
        <strong>
          <EventPlaceName event={event} />
        </strong>
      </div>
      <div>{getPlaceAddress(place?.address)}</div>
    </div>
  )
}

const DressCode: React.FC<{ event: Event }> = ({ event }) => {
  if (!event.dressCode) return null
  return (
    <p>
      <span className="single-event-subtitle">
        <i className="icon-shirt" />
        Dress
      </span>
      <span className="tag-green">{event.dressCode}</span>
    </p>
  )
}

const EventOrganizer: React.FC<{ event: Event }> = ({ event }) => {
  if (!event.organizer) return null
  return (
    <div>
      <h2 className="single-event-subtitle">
        <i className="icon-user" /> Organizer
      </h2>
      <div>
        <strong>{event.organizer.name}</strong>
        <br />
        <a href={`mailto:${event.organizer.email}`}>{event.organizer.email}</a>
        <br />
        {event.organizer.phone}
      </div>
    </div>
  )
}

const Sidebar: React.FC<{ event: Event }> = ({ event }) => {
  const eventStartAtDate = dayjs(event.startAt).startOf('day').toDate()
  return (
    <aside className="event-sidebar">
      <EmbeddedModalLinkBlocker
        to={Routes.newEvent()}
        className="btn btn-red btn-new"
        modalComponent={EventSignUpUpsell}
        modalCTALink={Routes.signUp(event.community)}
      >
        Submit an Event
      </EmbeddedModalLinkBlocker>

      <CalendarContainer selectedDay={eventStartAtDate} />
      {event.numberAnalytics && <Analytics {...event.numberAnalytics} />}
    </aside>
  )
}

const EventView: React.FC<{ event?: Event; loading?: boolean }> = ({ event, loading }) => {
  if (loading) return <Loading className="isCentered isDark" />
  if (event == null) {
    toastr.error('Please try again later', 'Server error')
    window.Rollbar && window.Rollbar.error("Couldn't find event")
    return null
  }

  return (
    <BodyClassName className="path-events">
      <section className="two-col event-view">
        <Metadata event={event} />

        <div className="event-main">
          <Link
            to={location => closestEventsPath(location.pathname)}
            css={css`
              display: inline-flex;
              align-items: center;
              gap: 6px;
              height: 25px;
              border-bottom: 1px solid #aaa;

              margin-top: 20px;
              @media (min-width: 768px) {
                margin-top: 0;
                margin-bottom: 10px;
              }
            `}
          >
            <span style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>←</span>
            <span>Back to Calendar</span>
          </Link>

          <article className="single-event-content">
            <EventHeader event={event} />

            <h2 className="single-event-subtitle">
              <i className="icon-info" /> Event Description
            </h2>

            <div className="single-event-interior">
              {/* interior left column */}
              <div className="single-event-copy ql-editor ql-snow">
                <p dangerouslySetInnerHTML={createMarkup(event.description)} />
                {event.isVirtual && (
                  <p className="single-event-attribution">
                    <br />
                    <a href={event.url} target="_blank" rel="noopener noreferrer">
                      {virtualPlaceCta(event)}
                    </a>
                  </p>
                )}
              </div>

              {/* interior right column or sidebar */}
              <div className="single-event-categories">
                {/*<EventQueueButton event={event} currentUser={currentUser} inline={false} />*/}

                <div className="event-tagging">
                  <p>
                    {event.isVirtual && (
                      <span className="single-event-subtitle">
                        <i className="icon-wifi-green" />
                        Online Event
                      </span>
                    )}
                  </p>
                  <p>
                    <span className="single-event-subtitle">
                      <i className="icon-folder" />
                      Category
                    </span>
                    <span className="tag-green">{event.category}</span>
                  </p>
                  <DressCode event={event} />
                  <ActionControls record={event} />
                </div>
              </div>
            </div>

            <footer className="single-event-footer">
              <div className="single-event-place" id="place">
                <EventVenue event={event} />
              </div>
              <div className="single-event-organizer">
                <EventOrganizer event={event} />
              </div>
            </footer>
          </article>
        </div>

        <Sidebar event={event} />
      </section>
    </BodyClassName>
  )
}

export default EventView
