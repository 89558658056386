import uniq from 'lodash/uniq'
import React, { useContext, useMemo, useState } from 'react'
import BodyClassName from 'react-body-classname'
import { Helmet } from 'react-helmet'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link, useParams } from 'react-router-dom'
import { Community, Story } from '../../../models'
import { CurrentUserContext } from '../../../providers/CurrentUserContext'
import Routes, { CommunitySlugRouteParams } from '../../../startup/routes'
import { getTimeFromNow } from '../../../util/dateTime'
import { isEmbedded } from '../../../util/window'
import { toggleBlocked } from '../../helpers/helpers'
import EmbeddedModalLinkBlocker from '../misc/EmbeddedModalLinkBlocker'
import FilterByCommunity from '../misc/FilterByCommunity'
import Loading from '../misc/Loading'
import { mediaSrc } from '../misc/MediaPresets'
import UserBadge from '../users/UserBadge'
import StoryListItem from './StoryListItem'

const MetaData: React.FC<{ communities: Community[]; featuredStory: null | Story }> = ({ communities, featuredStory }) => {
  const { idOrName: communityIdParam } = useParams<CommunitySlugRouteParams>()

  let community
  if (communityIdParam) {
    community = communities.find(c => c.slug.toLowerCase() === communityIdParam.toLowerCase())
  }

  if (!community) {
    return <Helmet defaultTitle="Stories | CivicLift" titleTemplate="Stories | %s | CivicLift" />
  }

  const openGraphImage = featuredStory && featuredStory.image

  return (
    <Helmet defaultTitle="Stories | CivicLift" titleTemplate="Stories | %s | CivicLift">
      <title key="community title">{community.displayName}</title>
      {community.storiesListMetaTitle && <meta property="og:title" content={community.storiesListMetaTitle} />}
      {community.storiesListMetaDescription && (
        <meta
          property="og:description"
          key="community meta description"
          name="description"
          content={community.storiesListMetaDescription}
        />
      )}
      {community.storiesListMetaKeywords && (
        <meta key="community meta keywords" name="keywords" content={community.storiesListMetaKeywords} />
      )}
      {openGraphImage && <meta property="og:image" content={mediaSrc(openGraphImage)} />}
      {<link rel="canonical" href={Routes.customUrl(community, Routes.stories(community))} />}
    </Helmet>
  )
}

const FeaturedStory: React.FC<{ story: Story }> = ({ story }) => {
  if (!story) return null

  const author = story.submitter || { firstName: 'Unknown', lastName: 'author' }
  const backgroundImageUrl = story.image ? `url(${mediaSrc(story.image)})` : null

  return (
    <article className="featured-entry fullWidth">
      <div className="featured-entry-content">
        <h1 className="featured-entry-heading">
          <Link to={Routes.story(story, story.community)}>{story.title}</Link>
        </h1>

        <div className="entry-meta">
          <UserBadge user={author} />
          <span className="meta-author">by {`${author.firstName} ${author.lastName || ''}`}</span>
          <span className="meta-category">in {story.category}</span>
          <span>
            &middot; {getTimeFromNow(story.publishedAt)}
            &nbsp; &middot; {story.readingTime}
          </span>
        </div>

        <div className="entry-excerpt">
          <p>{story.excerpt}</p>

          <Link to={Routes.story(story, story.community)} className="more">
            Read On <i className="icon-arrow-right" />
          </Link>
        </div>
      </div>
      <div className="featured-entry-bg" style={{ backgroundImage: backgroundImageUrl || undefined }} />
    </article>
  )
}

const AddStory: React.FC<{ communities: Community[] }> = ({ communities }) => {
  const { currentUser } = useContext(CurrentUserContext)

  const canEdit = useMemo(() => {
    if (!currentUser) {
      return false
    }

    return currentUser.isInRole('admin') || currentUser.hasRole('curator') || currentUser.hasRole('author')
  }, [currentUser])

  if (!canEdit) {
    return null
  }

  return (
    <section>
      <EmbeddedModalLinkBlocker
        to={Routes.newStory()}
        className="btn btn-blue btn-new"
        modalComponent={null}
        modalCTALink={Routes.signUp(communities[0])}
      >
        Submit Story
      </EmbeddedModalLinkBlocker>
    </section>
  )
}

const Sidebar: React.FC<{
  communities: Community[]
  blockedStoryCategories: string[]
  blockedStoryCommunities: string[]
  handleCategoryToggle: React.ChangeEventHandler<HTMLInputElement>
  handleCommunityToggle: React.ChangeEventHandler<HTMLInputElement>
}> = ({ communities, blockedStoryCategories, blockedStoryCommunities, handleCategoryToggle, handleCommunityToggle }) => {
  const { currentUser } = React.useContext(CurrentUserContext)

  const allStoryCategories = communities.flatMap(community => community.storyCategories || [])
  const storyCategories = uniq(allStoryCategories)

  return (
    <aside className="stories-sidebar">
      <AddStory communities={communities} />

      <div className="categories-widget">
        <h1 className="widget-heading">Categories</h1>

        <dl className="slide-toggle">
          <dt className="onoffswitch">
            <input
              type="checkbox"
              className="onoffswitch-checkbox"
              id="catswitch1"
              onChange={handleCategoryToggle}
              checked={blockedStoryCategories.length === 0}
            />
            <label className="onoffswitch-label" htmlFor="catswitch1" />
          </dt>
          <dd>Show All</dd>
        </dl>

        {storyCategories.map((category, index) => {
          const idName = `catswitch${index + 2}`
          return (
            <dl key={category} className="slide-toggle">
              <dt className="onoffswitch">
                <input
                  type="checkbox"
                  name={category}
                  className="onoffswitch-checkbox"
                  id={idName}
                  onChange={handleCategoryToggle}
                  checked={!blockedStoryCategories.includes(category)}
                />
                <label className="onoffswitch-label" htmlFor={idName} />
              </dt>
              <dd>{category}</dd>
            </dl>
          )
        })}
      </div>

      {currentUser && !isEmbedded() && (
        <FilterByCommunity communities={communities} blockedCommunities={blockedStoryCommunities} handleToggle={handleCommunityToggle} />
      )}
    </aside>
  )
}

interface Props {
  loading: boolean
  featuredStory: null | Story
  communities: Community[]
  stories: Story[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchNextPage: () => Promise<void>
  hasNextPage: boolean
}
const StoriesList: React.FC<Props> = ({
  loading,
  communities: allCommunities,
  featuredStory,
  stories: allStories,
  fetchNextPage,
  hasNextPage,
}) => {
  const [blockedStoryCategories, setBlockedStoryCategories] = useState<string[]>([])
  const [blockedStoryCommunities, setBlockedStoryCommunities] = useState<string[]>([])

  const communities = useMemo(
    () => allCommunities.filter(community => !blockedStoryCommunities.includes(community.id)),
    [blockedStoryCommunities, allCommunities],
  )

  const stories = useMemo(
    () =>
      allStories.filter(
        story =>
          !blockedStoryCategories.includes(story.category || '') &&
          (!story.community || !blockedStoryCommunities.includes(story.community.id)),
      ),
    [blockedStoryCategories, blockedStoryCommunities, allStories],
  )

  if (loading) return <Loading className="isCentered isDark" />

  const communityIds = communities.map(community => community.id)

  return (
    <BodyClassName className="path-stories">
      <section>
        <MetaData communities={communities} featuredStory={featuredStory} />
        {featuredStory && <FeaturedStory story={featuredStory} />}

        <div className="two-col">
          <section className="stories-main">
            <ul className="entries-list">
              <InfiniteScroll
                dataLength={stories.length}
                next={fetchNextPage}
                hasMore={hasNextPage}
                loader={<Loading className="isCentered isDark" />}
                style={{ overflow: 'hidden' }}
              >
                {stories.map(story => (
                  <StoryListItem communityIds={communityIds} story={story} key={story.id} />
                ))}
              </InfiniteScroll>
            </ul>
          </section>

          <Sidebar
            communities={allCommunities}
            blockedStoryCategories={blockedStoryCategories}
            blockedStoryCommunities={blockedStoryCommunities}
            handleCategoryToggle={toggleBlocked(setBlockedStoryCategories)}
            handleCommunityToggle={toggleBlocked(setBlockedStoryCommunities)}
          />
        </div>
      </section>
    </BodyClassName>
  )
}

export default StoriesList
