import { Community, Event, Place, Story } from '../../models'
import BaseModel from '../model'

interface Role {
  name: string
  resourceId?: string
}

class User extends BaseModel {
  public readonly objectType = 'user'

  private roles?: Role[]

  public firstName?: string
  public lastName?: string
  public email?: string
  public profilePic?: { publicId?: string }
  public primaryCommunity?: Community
  public subscribedEvents?: Event[]
  public subscribedStories?: Story[]
  public subscribedPlaces?: Place[]
  public signature?: string

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public constructor(doc) {
    super()
    const id = doc.id || doc._id
    Object.assign(this, { ...doc, _id: id, id })
  }

  public get fullName(): string {
    return [this.firstName, this.lastName].filter(Boolean).join(' ')
  }

  public isInRole(name: string, resourceId?: string): boolean {
    const { roles = [] } = this

    return roles.some(role => role.name === name && (role.resourceId == null || role.resourceId === resourceId))
  }

  public hasRole = (name: string): boolean => (this.roles || []).some(role => role.name === name)

  public get isAdmin(): boolean {
    return (this.roles || []).some(r => r.name === 'admin')
  }
}

export default User
