import get from 'lodash/get'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

import { useQuery } from '../../../api/graphql'
import OperatingHoursModel from '../../../api/places/operatingHoursModel'
import { CurrentUserContext } from '../../../providers/CurrentUserContext'
import Routes from '../../../startup/routes'
import { PlaceViewQuery } from '../../containers/PlaceViewContainer'
import Loading from '../misc/Loading'
import { mediaSrc } from '../misc/MediaPresets'

type OperatingHoursProps = { operatingHours: OperatingHoursModel }
const OperatingHours: React.FC<OperatingHoursProps> = ({ operatingHours }) => {
  const openCloseTimes = operatingHours.openCloseTimesToday()
  // this is an edge case when all operatingHours days open/close time are null
  // this means that operatingHours has not been set at all, rather than that the place is closed
  // so we don't show the place as being closed in this case:
  // operatingHours: { monday: { openTime: null, closeTime: null }, tuesday: { openTime: null, closeTime: null }, ...
  if (!operatingHours.isOpenNow && (!openCloseTimes || !openCloseTimes.openTime || !openCloseTimes.closeTime)) {
    return null
  }
  return (
    <p className="tooltip-hours">
      {operatingHours.isOpenNow ? (
        <>
          <strong className="u-green">Open now</strong>
          {` until ${operatingHours.closingTimeLabel}`}
        </>
      ) : (
        <strong className="u-red">Closed now</strong>
      )}
    </p>
  )
}

type Props = { placeSlug: string; communityIdParam?: string; closePopup: () => void }
const PlacePopup: React.FC<Props> = ({ placeSlug, communityIdParam, closePopup }) => {
  const { currentUser } = React.useContext(CurrentUserContext)
  const { loading, data: { place = null } = {} } = useQuery(PlaceViewQuery, {
    variables: { placeSlug },
    skip: !placeSlug,
  })

  const directionsUrl = useCallback(() => {
    const [lng, lat] = get(place, 'geo.geometry.coordinates')
    return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
  }, [place])

  const canEdit = React.useMemo(() => {
    if (currentUser) {
      const isAdmin = currentUser.isInRole('admin')
      const isCurator = currentUser.isInRole('curator', get(place, 'community.id'))
      // const isSubmittedByCurrentUser = currentUser.id === place.submittedBy;

      return isAdmin || isCurator
    }

    return false
  }, [currentUser, place])

  const renderContactPhone = () => {
    if (!get(place, 'contact.phone')) {
      return null
    }

    return (
      <p>
        <a href={`tel:${place.contact.phone}`} className="bulletIcon">
          <i className="icon-phone-red" />
          {place.contact.phone}
        </a>
      </p>
    )
  }

  if (loading) {
    return <Loading className="isDark" />
  }

  return (
    <article className="tooltip" id={`popup-${place.id}`}>
      <header className="tooltip-header">
        <h2 className="tooltip-header-title">{place.name}</h2>
        <p className="tooltip-header-subtitle">
          {/*{place.category} {place.pricing && <span className="tooltip-pricing"> • $$$$</span>}*/}
        </p>
        <button className="close-tooltip" onClick={() => closePopup()}>
          <i className="icon-close" />
        </button>
      </header>

      {place.isPremium && (
        <Link to={Routes.premiumPlace(place, communityIdParam)} className="tooltip-image">
          <img
            src={mediaSrc({
              ...place.mainImage,
              dpr: '2.0',
              width: '330',
              height: '150',
            })}
            alt=""
          />
          <div className="tooltip-image-overlay">
            <div className="tooltip-image-overlay-text">
              View page <i className="icon-arrow-right" />
            </div>
          </div>
        </Link>
      )}

      <div className="tooltip-details">
        <p className="tooltip-description">{place.shortDescription}</p>
        <div className="tooltip-cols">
          <div className="tooltip-details-col">
            <OperatingHours operatingHours={place.operatingHours} />
            {renderContactPhone()}
            <p>
              <a className="bulletIcon" href={directionsUrl()} rel="noopener noreferrer" target="_blank">
                <i className="icon-pin-green" />
                {place.address.streetAddress} <br /> {place.address.city}
              </a>
            </p>
            {/*{!place.submittedBy && (*/}
            {/*  <p className="tooltip-claim">*/}
            {/*    Is this your business?{' '}*/}
            {/*    <a*/}
            {/*      onClick={() => {*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      Claim it!*/}
            {/*    </a>*/}
            {/*  </p>*/}
            {/*)}*/}
            {canEdit && (
              <Link to={Routes.editPlace(place)}>
                <i className="icon-edit-place" />
                Edit
              </Link>
            )}
          </div>
          {/*{place.logo && (*/}
          {/*  <div className="tooltip-img-col">*/}
          {/*    <img*/}
          {/*      src={mediaSrc({*/}
          {/*        ...place.logo,*/}
          {/*        dpr: '2.0',*/}
          {/*        width: '330',*/}
          {/*        height: '150',*/}
          {/*      })}*/}
          {/*      alt={place.name}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*)}*/}
          {place.webUrl && (
            <div className="tooltip-details-col">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={place.webUrl}
                className="btn btn-darkblue"
                style={{
                  textTransform: 'capitalize',
                }}
              >
                Visit Website
              </a>
            </div>
          )}
        </div>
      </div>
    </article>
  )
}

export default PlacePopup
