import { ApolloCache } from 'apollo-cache'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, Observable } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import toastr from 'toastr'
import introspectionQueryResultData from '../schemas/fragments.js'
import { LocalStorage } from './window.js'

// https://www.apollographql.com/docs/react/advanced/fragments/#fragment-matcher
const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData })

export const createCache = (): ApolloCache<InMemoryCache> => {
  const cache = new InMemoryCache({ fragmentMatcher, dataIdFromObject: object => object.id })

  // TODO: Monkey-patching in a fix for an open issue suggesting that
  // `readQuery` should return null or undefined if the query is not yet in the
  // cache: https://github.com/apollographql/apollo-feature-requests/issues/1
  cache.originalReadQuery = cache.readQuery
  cache.readQuery = (...args) => {
    try {
      return cache.originalReadQuery(...args)
    } catch (err) {
      return undefined
    }
  }

  return cache
}

// get CSRF Token from meta tags
const getTokens = () => ({
  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
})

const setTokenForOperation = async operation => operation.setContext({ headers: getTokens() })
// link (middleware) with token
const createLinkWithToken = () =>
  new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        let handle
        Promise.resolve(operation)
          .then(setTokenForOperation)
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            })
          })
          .catch(observer.error.bind(observer))
        return () => {
          if (handle) {
            handle.unsubscribe()
          }
        }
      }),
  )

const createErrorLink = () =>
  onError(({ graphQLErrors, networkError, operation }) => {
    toastr.error('Please try again later', 'Server error')

    const fullGraphQlErrors = graphQLErrors?.map(e => e.message)?.join('\n') || ''
    const isUnknownCommunityError = fullGraphQlErrors.includes('Can\'t find record with slug: "')
    if (isUnknownCommunityError) LocalStorage.removeItem('customDomainOrigin')

    const requestArgs = { operation: operation.operationName, variables: operation.variables }
    const error = networkError || fullGraphQlErrors

    console.error(error, requestArgs)
    window.Rollbar && window.Rollbar.error(error, requestArgs)
  })

// http link
const createHttpLink = () => new HttpLink({ uri: '/graphql', credentials: 'include' })

export const createClient = (cache: ApolloCache<InMemoryCache>): ApolloClient<InMemoryCache> =>
  new ApolloClient<InMemoryCache>({
    link: ApolloLink.from([createErrorLink(), createLinkWithToken(), createHttpLink()]),
    cache,
    shouldBatch: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
      },
      query: {
        fetchPolicy: 'network-only',
      },
      mutate: {
        fetchPolicy: 'network-only',
      },
    },
  })
