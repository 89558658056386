import { css } from '@emotion/react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import gql from 'graphql-tag'
import get from 'lodash/get'
import React, { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { BaseField } from 'uniforms'
import { AutoField, BoolField, ErrorField, ErrorsField, NestField, RadioField, SelectField } from 'uniforms-unstyled'
import { eventsSummary, ordinalWeekOfMonth } from '../../../api/events/helpers'
import { UseOnImageUploadStateChangeReturnType } from '../../../hooks/useOnImageUploadStateChange'
import { Event, Place } from '../../../models'
import { BUY_TICKETS_ONLINE, MULTIPLE_PRICING_TIERS, ONE_FLAT_PRICE, REGISTER_OR_RSVP_ONLINE } from '../../../schemas/events/eventSchema'
import Grid, { GridItem } from '../../../styles/Grid'
import { formFieldSpacing } from '../../../styles/shared'
import { borderRadius, gap } from '../../../styles/vars'
import { EventTypeDescription, EventTypeLabel, EventTypes, RepeatingPatterns, repeatingPatternIsCustom } from '../../../util/constants'
import { dayOfTheWeek } from '../../../util/dateTime'
import EventCategories from '../../../util/eventCategories'
import AddressFields from '../form-fields/AddressFields'
import CommunityField from '../form-fields/CommunityField'
import DatePicker from '../form-fields/DatePicker'
import DisplayIf from '../form-fields/DisplayIf'
import GeocodeAddressFields from '../form-fields/GeocodeAddressFields'
import MoneyField from '../form-fields/MoneyField'
import SelectTags from '../form-fields/SelectTags'
import UniformMediaUploadField from '../form-fields/uniform-fields/UniformMediaUploadField'
import RichTextEditor from '../form-fields/uniform-fields/UniformRichTextEditor'
import PhoneField from '../misc/PhoneField'
import RecordSearchAutocomplete from '../misc/RecordSearchAutocomplete'
import EventOccurrences from './EventOccurrences'
import StartDateTimeFields from './StartDateTimeFields'
import WeeklyRepeatingDaysSelector from './WeeklyRepeatingDaysSelector'

const RadioBoxSwitcher = styled(RadioField)`
  display: flex;
  gap: ${gap};
  margin-bottom: calc(${gap} / 2);

  & > div {
    flex: 1;

    display: flex;
    & > label {
      flex: 1;
    }
  }
`

const RadioBox = styled.div<{ $selected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @media (min-width: 768px) {
    align-items: center;
  }
  border-radius: ${borderRadius};
  padding: 20px;
  min-height: 80px;
  height: 100%;
  line-height: 1.2;
  font-weight: normal;

  background: #fff;
  color: #434343;
  border: 1px solid #d8d8d8;
  ${props =>
    props.$selected &&
    `
    background: rgba(111,191,83,0.1);
    color: #42872A;
    border: 1px solid #6FBF53;
  `}
`

const RadioBoxDescription = styled.div`
  font-style: italic;
  font-size: 13px;
  margin-top: 8px;
`

const FieldsetDescription = styled.div`
  font-size: 14px;
  margin-top: -20px;
  padding-bottom: 40px;
  color: #102638;
`

const SummaryBox = styled.div`
  margin-top: calc(${gap} * 2 - 14px);
  border: 1px solid #d8d8d8;
  border-radius: ${borderRadius};
  background-color: #ededed;
  padding: 20px;
`

export const StyledLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #434343;
  margin-bottom: 5px;
`

const InputHint = styled.div`
  font-size: 13px;
  font-style: italic;
  padding-left: ${gap};
`

const Summary: React.FC = (_props, { uniforms: { model } }) => {
  if (!model.isNew) return null

  const summary = eventsSummary(model)
  if (!summary) return null

  return (
    <SummaryBox>
      <StyledLabel>Summary</StyledLabel>
      <InputHint>{summary}</InputHint>
      <EventOccurrences />
    </SummaryBox>
  )
}
Summary.contextTypes = BaseField.contextTypes

const RepeatsDropdown: React.FC = (_props, { uniforms: { model } }) => {
  if (!model.startAt) return null

  const options = [
    { label: 'Daily', value: RepeatingPatterns.DAILY },
    { label: 'Weekly', value: RepeatingPatterns.WEEKLY },
    {
      label: `Monthly on a day (such as "the ${ordinalWeekOfMonth(model.startAt)} ${dayOfTheWeek(model.startAt)}")`,
      value: RepeatingPatterns.MONTHLY_NTH_DOW,
    },
    {
      label: `Monthly on a date (such as "the ${dayjs(model.startAt).format('Do')}")`,
      value: RepeatingPatterns.MONTHLY_SAME_DATE,
    },
    { label: 'Custom', value: RepeatingPatterns.REQUEST_SCHEDULE },
  ]
  return (
    <>
      <SelectField
        label={<StyledLabel style={{ marginTop: '24px' }}>Recurrence Pattern</StyledLabel>}
        placeholder="Select"
        name="repeats"
        value={model.repeats}
        options={options}
        css={css`
          select {
            width: 100% !important;
          }
        `}
      />
      <div
        className="form-error"
        css={css`
          &:before {
            display: none !important;
          }
        `}
      >
        <ErrorField
          name="repeats"
          css={css`
            margin-top: 0 !important;
          `}
        />
      </div>
    </>
  )
}
RepeatsDropdown.contextTypes = BaseField.contextTypes

const DateFieldsForEventType: React.FC = (_props, { uniforms: { model } }) => {
  if (!model.eventType) return null

  if (model.eventType === EventTypes.SINGLE_EVENT || !model.isNew) {
    return (
      <>
        <StartDateTimeFields />
        <Summary />
      </>
    )
  }

  return (
    <>
      <StartDateTimeFields label={<StyledLabel>Start Date</StyledLabel>} />
      <RepeatsDropdown />
      {!repeatingPatternIsCustom(model.repeats) && (
        <>
          <WeeklyRepeatingDaysSelector />
          <StyledLabel style={{ marginTop: '24px' }}>Last Date</StyledLabel>
          <Grid>
            <GridItem columns={12} medium={6}>
              <DatePicker id="repeatStopsOn" name="repeatStopsOn" className="dateAndTimeFields-date" />
            </GridItem>
            <GridItem columns={12} medium={6} />
          </Grid>
          <Summary />
        </>
      )}
    </>
  )
}
DateFieldsForEventType.contextTypes = BaseField.contextTypes

const DateFields: React.FC = (_props, { uniforms: { model } }) => (
  <fieldset>
    <h2 className="hr-heading">When</h2>
    <div css={css(formFieldSpacing)}>
      <FieldsetDescription>Specify when your event is taking place.</FieldsetDescription>
      {model.isNew && (
        <RadioBoxSwitcher
          name="eventType"
          allowedValues={[EventTypes.SINGLE_EVENT, EventTypes.REPEATING_EVENT]}
          label={null}
          transform={v => (
            <RadioBox $selected={v === model.eventType}>
              <div>{EventTypeLabel[v]}</div>
              <RadioBoxDescription>{EventTypeDescription[v]}</RadioBoxDescription>
            </RadioBox>
          )}
        />
      )}
      <DateFieldsForEventType />
    </div>
  </fieldset>
)
DateFields.contextTypes = BaseField.contextTypes

type Props = { originalEvent: Event; onImageUploadStateChange: UseOnImageUploadStateChangeReturnType }
const EventFields: React.FC<Props> = ({ onImageUploadStateChange, originalEvent }, { uniforms: { model, onChange } }) => {
  const recordSearchValue = useCallback(() => {
    if (model.placeId && model.placeId === get(model, 'place.id')) {
      return { value: model.placeId, label: model.place.title }
    } else {
      return model.placeId
    }
  }, [model])

  const isRepeatingEvent = originalEvent?.repeatingEventId
  const tags = [...(model.tags || []), ...EventCategories]
  const tempPlaceAddress = model.tempPlace ? model.tempPlace.address : null
  let title = 'Event'
  title = `${title} ${model.isNew ? 'Add' : 'Edit'}`
  title = `${title}${model.title ? `: ${model.title}` : ''}`

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <fieldset>
        <fieldset>
          <h2 className="hr-heading">Event Info</h2>

          <CommunityField />
          <div className="form-error">
            <ErrorField name="primaryCommunityId" />
          </div>

          <AutoField name="title" className="input-row" placeholder=" " />
          <div className="form-error">
            <ErrorField name="title" />
          </div>

          <RichTextEditor
            name="description"
            isRequired
            className="input-row"
            onUploadingStateChange={onImageUploadStateChange}
            toolbar={[['bold', 'italic'], ['link'], [{ list: 'ordered' }, { list: 'bullet' }]]}
          />
          <div className="form-error">
            <ErrorField name="description" />
          </div>

          <div
            css={css`
              display: flex;
              margin-bottom: 14px;

              flex-wrap: wrap;
              @media (min-width: 768px) {
                flex-wrap: nowrap;
                gap: 20px;
              }

              && > div {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 14px;

                @media (min-width: 768px) {
                  flex-wrap: nowrap;
                  align-items: center;
                }
              }

              & > div {
                width: 100%;
                @media (min-width: 768px) {
                  width: initial;
                }
              }
              && select {
                width: 100%;
                @media (min-width: 768px) {
                  width: auto;
                }
              }
              .input-row.input-row {
                flex-basis: 140px;
                flex-wrap: nowrap;
                margin-bottom: 0;

                flex-direction: column;
                @media (min-width: 768px) {
                  flex-direction: row;
                }
              }
              .input-row.input-row input {
                max-width: 140px;
                min-height: 46px;
              }
              .input-row.input-row label {
                align-items: baseline;
                @media (min-width: 768px) {
                  text-align: right;
                  flex-basis: 0;
                }
              }

              label {
                line-height: 32px;
                margin-right: 16.688px;

                width: 100%;
                text-align: left;
                @media (min-width: 768px) {
                  width: 104.09px;
                  text-align: right;
                }
              }
              .input-row.input-row label {
                color: #959595;
                padding-right: 4px;
              }
              .costMinMax {
                width: 100%;
                gap: 10px;
              }
              .inputWithHint {
                position: relative;
              }
              .inputWithHint-hint {
                position: absolute;
                top: 100%;
                color: #959595;
                font-size: 12px;

                left 0;
                @media (min-width: 768px) {
                  left: 16px;
                }
              }
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            input[type="number"] {
              touch-action: none;
              -moz-appearance: textfield;
            }
            `}
          >
            <SelectField name="costTier" placeholder="Select Cost" />

            <DisplayIf condition={context => context.model.costTier === ONE_FLAT_PRICE}>
              <div className="costMinMax">
                <MoneyField name="costMin" className="input-row" />
              </div>
            </DisplayIf>
            <DisplayIf condition={context => context.model.costTier === MULTIPLE_PRICING_TIERS}>
              <div className="costMinMax">
                <div className="inputWithHint" style={{ position: 'relative' }}>
                  <MoneyField name="costMin" className="input-row" />
                  <span className="inputWithHint-hint">Lowest Price</span>
                </div>
                <div className="inputWithHint" style={{ position: 'relative' }}>
                  <MoneyField name="costMax" className="input-row" />
                  <span className="inputWithHint-hint">Highest Price</span>
                </div>
              </div>
            </DisplayIf>
          </div>
          <div className="form-error">
            <ErrorField name="costTier" />
          </div>

          <div
            css={css`
              display: flex;
              margin-bottom: 14px;

              flex-direction: column;
              @media (min-width: 768px) {
                gap: 20px;
                flex-direction: row;
              }

              & > div {
                display: flex;
                margin-bottom: 14px;

                flex-direction: column;
                @media (min-width: 768px) {
                  align-items: center;
                  flex-direction: row;
                }
              }
              label {
                line-height: 32px;
                width: 104.09px;
                margin-right: 16.688px;

                text-align: left;
                @media (min-width: 768px) {
                  text-align: right;
                }
              }
              && .input-row [type='text'] {
                width: 100%;
              }
              .inputWithHint {
                position: relative;
                width: 100%;
              }
              .inputWithHint .input-row {
                margin-bottom: 0;
              }
              .inputWithHint-hint {
                position: absolute;
                left 0;
                top: 100%;
                color: #959595;
                font-size: 12px;
              }
            `}
          >
            <SelectField name="needTicketOrRsvp" placeholder="Select" />
            <DisplayIf condition={context => context.model.needTicketOrRsvp === BUY_TICKETS_ONLINE}>
              <div className="inputWithHint">
                <AutoField name="ticketOrRsvpUrl" className="input-row" placeholder="https://" />
                <span className="inputWithHint-hint">Link for purchasing tickets.</span>
              </div>
            </DisplayIf>
            <DisplayIf condition={context => context.model.needTicketOrRsvp === REGISTER_OR_RSVP_ONLINE}>
              <div className="inputWithHint">
                <AutoField name="ticketOrRsvpUrl" className="input-row" placeholder="https://" />
                <span className="inputWithHint-hint">Link for registration.</span>
              </div>
            </DisplayIf>
          </div>
          <div className="form-error">
            <ErrorField name="ticketOrRsvpUrl" />
          </div>

          <div
            css={css`
              ${formFieldSpacing};
              width: 282px;
              height: 165px;
            `}
          >
            <UniformMediaUploadField
              allowVideo={false}
              folder="events"
              label="Add Image"
              name="image"
              onUploadingStateChange={onImageUploadStateChange}
            />
          </div>
        </fieldset>

        <DateFields />

        <fieldset>
          <h2 className="hr-heading">Categories</h2>

          <div className="double-input-row">
            <div className="input-col">
              <SelectField className="isRequired" name="category" placeholder="Select Category" />
            </div>
            <div
              className="form-error"
              css={css`
                display: block !important;
                @media (min-width: 768px) {
                  display: none !important;
                }
              `}
            >
              <ErrorField name="category" />
            </div>

            <div className="input-col">
              <SelectField name="type" placeholder="Select Event Type" />
            </div>
          </div>
          <div
            className="form-error"
            css={css`
              display: none !important;
              @media (min-width: 768px) {
                display: block !important;
              }
            `}
          >
            {/* Although the field above is `type`, we show `category` error field here
            so that it looks nice on desktop. `type` field is not required. */}
            <ErrorField name="category" />
          </div>

          <div className="double-input-row">
            <div className="input-col">
              <SelectField name="dressCode" placeholder="Select Dress Code" />
            </div>
          </div>

          <SelectTags name="tags" tags={tags} />
          <div className="form-error">
            <ErrorField name="tags" />
          </div>
        </fieldset>

        <fieldset>
          <h2 className="hr-heading">Venue / Place</h2>

          <BoolField name="isVirtual" className="checkbox-row" />

          <DisplayIf condition={context => context.model.isVirtual}>
            <>
              <section className="input-row isRequired">
                <AutoField name="url" placeholder="https://example.com" className="input-row" />
              </section>
              <div className="form-error">
                <ErrorField name="url" style={{ marginTop: '-24px' }} />
              </div>
            </>
          </DisplayIf>

          <DisplayIf condition={context => !context.model.isVirtual}>
            <>
              <section className="input-row isRequired">
                <label htmlFor="placeSearch">Venue</label>
                <RecordSearchAutocomplete
                  id="placeSearch"
                  name="placeId"
                  value={recordSearchValue()}
                  onChange={(place: Place) => onChange('placeId', place && place.id)}
                  placeholder={model.useTempPlace ? 'Enter venue details below' : 'Search for a Place...'}
                  options={model.useTempPlace ? [] : undefined}
                  disabled={model.useTempPlace}
                  noResultsText="Start typing to search..."
                  searchProps={{
                    recordsType: 'places',
                    communityId: model.primaryCommunityId,
                    query: gql`
                      query RecordSearch($searchTerm: String!, $communityId: ID, $recordsType: RecordEnum) {
                        search(searchTerm: $searchTerm, communityId: $communityId, recordsType: $recordsType) {
                          ... on Place {
                            id
                            name
                          }
                        }
                      }
                    `,
                  }}
                />
              </section>
              <div className="form-error">
                <ErrorField name="placeId" />
              </div>

              <BoolField name="useTempPlace" className="checkbox-row" />

              <DisplayIf condition={() => model.useTempPlace}>
                <NestField name="tempPlace" label={null}>
                  <AutoField name="name" placeholder="Name" className="input-row" />
                  <div className="form-error">
                    <ErrorField name="name" />
                  </div>

                  <GeocodeAddressFields name="address" className="input-row" address={tempPlaceAddress} />

                  <AddressFields name="address" address={model.address} />
                  <div className="form-error">
                    <ErrorField name="address" />
                  </div>

                  <AutoField name="url" placeholder="https://example.com" className="input-row" />
                  <div className="form-error">
                    <ErrorField name="url" />
                  </div>
                </NestField>
              </DisplayIf>
            </>
          </DisplayIf>
        </fieldset>

        <fieldset>
          <h2 className="hr-heading">Organizer Info</h2>

          <BoolField name="submitterOwnsEvent" className="checkbox-row" />

          <NestField name="organizer" label={null}>
            <AutoField name="name" label="Organizer" placeholder="Full Name" className="input-row" disabled={model.submitterOwnsEvent} />
            <div className="form-error">
              <ErrorField name="name" />
            </div>
          </NestField>

          <NestField name="organizer" label={null}>
            <div className="phone">
              <PhoneField name="phone" id="organizer.phone" label="Phone" />
              <div className="form-error">
                <ErrorField name="phone" />
              </div>
            </div>

            <div className="email">
              <AutoField className="input-row" name="email" placeholder="name@example.com" disabled={model.submitterOwnsEvent} />
              <div className="form-error">
                <ErrorField name="email" />
              </div>
            </div>
          </NestField>
        </fieldset>

        <div
          className="form-error"
          css={css`
            padding-top: 30px;
            margin-bottom: -10px;
          `}
        >
          <ErrorsField />
        </div>
      </fieldset>

      <DisplayIf condition={() => isRepeatingEvent}>
        <BoolField name="updateAllFollowingEventsInSeries" className="checkbox-row" />
      </DisplayIf>
    </>
  )
}

EventFields.contextTypes = BaseField.contextTypes

export default EventFields
