import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import BodyClassName from 'react-body-classname'
import { Redirect } from 'react-router'
import useIsLiveCommunity from '../../../hooks/useIsLiveCommunity'
import { CurrentUserContext } from '../../../providers/CurrentUserContext'
import Routes from '../../../startup/routes'
import UserBadge from '../users/UserBadge'
import UserSidebar from './UserSidebar'

export const DashboardContext = createContext({})

const DashboardLayout: React.FC = ({ children }) => {
  const { currentUser, loading: loadingCurrentUser } = useContext(CurrentUserContext)

  const isAdmin = currentUser && currentUser.isInRole('admin')
  const isCurator = currentUser && currentUser.hasRole('curator')

  const [isMobileSidebarExpanded, setIsMobileSidebarExpanded] = useState(false)
  const { isLive } = useIsLiveCommunity(currentUser?.primaryCommunity?.slug)

  const toggleMobileSidebar = useCallback(() => {
    setIsMobileSidebarExpanded(!isMobileSidebarExpanded)
  }, [isMobileSidebarExpanded, setIsMobileSidebarExpanded])

  const shrinkMobileSidebar = useCallback(() => {
    setIsMobileSidebarExpanded(false)
  }, [setIsMobileSidebarExpanded])

  const renderSidebar = useCallback(() => {
    let userGreeting = 'Welcome back'
    let firstName

    if (currentUser?.firstName) {
      userGreeting += ','
      firstName = currentUser.firstName
    }

    let className = 'dash-sidebar'
    if (isMobileSidebarExpanded) {
      className += ' wide'
    }

    return (
      <aside>
        <div className={className}>
          <div className="dash-sidebar-mobileToggle" onClick={toggleMobileSidebar}>
            <i className="icon-arrow-right" />
          </div>

          <div className="dash-sidebar-primary">
            <div className="dash-user-info">
              <dl className="user-lockup">
                <dt className="user-lockup-avatar">
                  <UserBadge user={currentUser} />
                </dt>
                <dd className="user-lockup-text">
                  <p className="user-lockup-greeting">{userGreeting}</p>
                  {firstName && <p className="user-lockup-username">{firstName}</p>}
                </dd>
              </dl>
            </div>

            {(isAdmin || isCurator) && (
              <div className="dash-nav-block">
                <a href="/admin" className="link sidebar-curate">
                  <i className="icon-star-full-yellow" />
                  <span className="link-text"> Curate</span>
                </a>
              </div>
            )}
            <UserSidebar onLinkClick={shrinkMobileSidebar} />
          </div>
        </div>
        <div className="dash-sidebar-overlay" onClick={shrinkMobileSidebar} />
      </aside>
    )
  }, [isAdmin, isCurator, isMobileSidebarExpanded, currentUser, shrinkMobileSidebar, toggleMobileSidebar])

  const dashboardContext = useMemo(() => ({ isLive }), [isLive])

  if (!loadingCurrentUser && !currentUser) {
    return <Redirect to={Routes.unauthenticated()} />
  }

  return (
    <BodyClassName className={`dashboard ${isMobileSidebarExpanded ? 'is-sidebarExpanded' : ''}`}>
      <DashboardContext.Provider value={dashboardContext}>
        <div className="dash">
          {renderSidebar()}
          <section className="dash-content">{children}</section>
        </div>
      </DashboardContext.Provider>
    </BodyClassName>
  )
}

export default DashboardLayout
