// needs to be synced with
//  - app/assets/javascripts/active_admin.js
//  - app/models/concerns/event_constants.rb
const EventCategories = [
  'Music',
  'Food & Drink',
  'Local Farm / Agricultural',
  'Business',
  'Arts',
  'History & Culture',
  'Health',
  'Family & Education',
  'Film & Media',
  'Community',
  'Sports & Fitness',
  'Spirituality',
  'Charity & Causes',
  'Fashion',
  'Hobbies',
  'Travel & Outdoor',
  'Science & Tech',
  'Holiday',
  'Auto, Boat & Air',
  'Government',
  'Home & Lifestyle',
  'Other',
]
export default EventCategories
